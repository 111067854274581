<template>
  <div class="at-chat_messages" :class="{'at-chat_messages_group':chatInfo.chatType == 2 }">
    <no-record-found :listIcon="'guppy-message-circle'" v-if="isEmptyView" />
    <template v-else>
      <template v-if="isOpenMessagesScr">
        <guppy-chat-message-header v-if="chatInfo.chatId" />
        <messages-container :conversationData="convData"/>
        <div v-if="chatInfo.isBlocked && chatInfo.blockedId == userId" class="at-chatblockuser" >
          <span> {{ TRANS.you_are_blocked }} </span>
        </div>
        <div v-else-if="chatInfo.isBlocked && chatInfo.blockedId != userId" class="at-chatblockuser" >
          <span v-html="unblockButtonText"></span>
        </div>
        <!-- in case of group disable footer area  -->
        <template v-else-if="memberDisable.isDisable">
          <div class="at-leftgroupinfo">
            <span>{{memberDisable.disableText}}</span>
          </div>
        </template>
        <!-- for BP group deleted -->
        <template v-else-if="deleteBPGroup.isDeleted">
          <div class="at-group-disabled"><span>{{deleteBPGroup.disableText}}</span></div>
        </template>
        <template v-else-if="disableReply && (getUserRole == 0)">
          <div class="at-group-disabled"><span>{{TRANS.disable_reply_txt}}</span></div>
        </template>
        <!-- for support member -->
        <template v-else-if="noLongerSupportMember">
          <div class="at-group-disabled"><span>{{TRANS.not_support_meber_txt}}</span></div>
        </template>
        <guppy-chat-message-footer v-else-if="chatInfo.chatId"
          :isDisabledFooter="loadChat && !messagesList.length ? true : isDisabledFooter"
        />
      </template>
      <guppy-chat-invite-response
        @loadUserChat="loadUserChat($event)"
        v-else
      />
    </template>
  </div>
</template>

<script>
import GuppyChatInviteResponse from "../GuppyChatInviteResponse/GuppyChatInviteResponse.vue";
import GuppyChatMessageHeader from "../GuppyChatMessageHeader/GuppyChatMessageHeader.vue";
import GuppyChatMessageFooter from "../GuppyChatMessageFooter/GuppyChatMessageFooter.vue";
import { GUPPYCHAT_TRANSLATION } from "../../../resources/constants";
import GuppyChatEmptyView from "../../EmptyViews/GuppyChatEmptyView.vue";
import GuppyChatMessagesContainer from "./GuppyChatMessagesContainer.vue";
import { mapState } from "vuex";
import GuppyChatManager from "../../../resources/controller";
import jQuery from 'jquery';
export default {
  name: "GuppyChatMessages",
  components: {
    NoRecordFound: GuppyChatEmptyView,
    GuppyChatInviteResponse,
    GuppyChatMessageHeader,
    GuppyChatMessageFooter,
    MessagesContainer :GuppyChatMessagesContainer
  },
  data() {
    return {
      chatData: "",
      oldVal: "",
      onlineStatus: "",
      isSending: false,
      mediaAttachments: [],
      convData: {
        offset      : 0,
        userId      : "",
        chatType    : "",
        receiverId  : "",
        groupId     : "",
        postId      : "",
        chatId      : "",
      },
      selectChatUser: {
        chatId: "",
        chatType: "",
      },
    };
  },
  watch:{
    chatInfo : {
      handler : function(){
        if(this.isMessangerChat){
          this.updateConvData();
          if (!this.messagesList.length) {
            this.loadChatMessages();
          }
          this.scrollListToBottom();
          let toggleData = { isEmptyView: false, isOpenMessagesScr: true };
          this.$store.dispatch("toggleMessagesScr", toggleData);
        }
      }
    }
  },
  computed: {
    noLongerSupportMember(){
      let chatInfo = this.$store.getters.getChatInfo();
      if( chatInfo.chatType == 3 ){
        return !chatInfo.isSupportMember
      }
      return false;
    },
    messagesList(){
      let chatId = ''
      if(this.$store.state.isMessangerChat){
          chatId = this.$store.state.chatInfo.chatId
      } else {
          chatId = this.chatId;
      }
      return this.$store.getters.messagesListData(chatId);
    },
    loadChat(){
      if(this.$store.state.isMessangerChat){
        return this.$store.getters.loadChat(this.$store.state.chatInfo.chatId);
      }
      return true;
    },
    memberDisable(){
      return this.$store.getters.memberDisable();
    },
    isDisabledFooter(){
      return this.$store.getters.isDisabledFooter();
    },
    deleteBPGroup(){
      return this.$store.getters.deleteBPGroup();
    },
    disableReply : function(){
      return this.$store.getters.disableReply();
    },
    getUserRole(){
      return this.$store.getters.getUserRole();
    },
    chatInfo(){
      return this.$store.getters.getChatInfo();
    },
    ...mapState({
      userResponseData  : (state) => state.userResponseData,
      isOpenMessagesScr : (state) => state.isOpenMessagesScr,
      isEmptyView       : (state) => state.isEmptyView,
      isMessangerChat   : (state) => state.isMessangerChat,
      unblockButtonText() {
        let text        = GUPPYCHAT_TRANSLATION.blocked_user_message;
        let buttonText  = GUPPYCHAT_TRANSLATION.unblock_now;
        let button      = '<a href="javascript:;" guppy-data-target="#blockuser">' + buttonText + " </a>";
        let updatedText = text.replace("%Unblock%", button);
        return updatedText;
      },
    }),
  },
  methods: {
    updateConvData(){
      let offset  = 0;
      let userId  = "", chatType = "", receiverId = "", groupId = "", postId = "", chatId ="";
      chatType    = Number(this.chatInfo.chatType);
      chatId      = this.chatInfo.chatId;
      userId      = this.userId;
      if ( [1,3].includes(Number(this.chatInfo.chatType)) ) {
          groupId    = postId = "";
          receiverId = chatId.split('_')[0];
      } else if (this.chatInfo.chatType == 2) {
          receiverId  = postId = "";
          groupId     = chatId.split('_')[0];
      } else if (this.chatInfo.chatType == 0 ) {
          postId      = chatId.split('_')[0];
          receiverId  = chatId.split('_')[1];
          groupId     = "";
      }
      this.convData = { offset, userId, chatType,receiverId,groupId, postId, chatId }
    },
    loadChatMessages() {
        if (this.loadChat) {
            this.$store.dispatch("UpdateMessageList", {
                chatData: this.convData,
                lastScrollTop: this.lastScrollTop ? this.lastScrollTop : 0,
            });
        }
    },
    loadUserChat(parems) {
      this.convData = {
        offset      : 0,
        userId      : this.userId,
        chatType    : 1,
        receiverId  : parems.userId,
        groupId     : "",
        postId      : "",
        chatId      : parems.chatId,
      };
      this.loadChatMessages();
    },
    scrollListToBottom(scrollHeight = 0) {
      GuppyChatManager.messagesScrollList(scrollHeight, this.chatInfo.chatId);
    },
  },
   mounted(){
    setTimeout(()=>{
      jQuery('.at-chat_messages').removeClass('at-chat_transition_none');
    },1000)
  }
};
</script>

<style scoped>
.at-chat_messages {
  background-color: #f7f7f7;
  flex-direction: column;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 72%;
  flex: 0 0 72%;
  max-width: 72%;
  display: -ms-flexbox;
  display: flex;
  transition: all 0.3s ease-in-out;
}
.at-chat_messagesslide {
  -ms-flex: 0 0 calc(72% - 300px);
  flex: 0 0 calc(72% - 300px);
  max-width: calc(72% - 300px);
}

.at-unknownuser {
  padding-left: 30px;
  padding-right: 30px;
}

.at-loadermsg {
  display: flex;
  align-items: center;
  padding: 20px;
  color: var(--terguppycolor);
  height: auto;
  bottom: auto;
}
.at-loadermsg + .at-message_time {
  display: none;
}
.at-loadermsg i {
  font-size: 24px;
  display: inline-block;
  margin-right: 12px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
.at-chatseparation {
  width: 100%;
  position: relative;
  margin: 10px 0;
  z-index: 1;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.at-chatseparation:before {
  height: 1px;
  width: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  z-index: -1;
  background: #ddd;
}
.at-chatseparation span {
  color: var(--terguppycolor);
  background: #f7f7f7;
  padding: 0 20px;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.5px;
}
</style>